import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Chart } from './Chart';
import { Card } from '../Common';
import Container from '@mui/material/Container';
import { useAppState } from '../../../context/appState';

const getReportData = (checkItems: []) => {
    const report = {
        total: 0,
        complete: 0,
        incomplete: 0,
        overDue: 0,
    };
    checkItems.forEach((checkItem) => {
        const state = checkItem['checkItemState'];
        if (
            state === '' ||
            checkItem['checkItemDueDate'] === '' ||
            checkItem['checkItemMemberName'] === ''
        ) {
            return;
        }
        // @ts-ignore
        report[state] += 1;
    });
    report['total'] =
        report['complete'] + report['incomplete'] + report['overDue'];
    return report;
};

export function MemberReport() {
    const { checkItems } = useAppState();
    const report = getReportData(checkItems);
    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <>
                    {/* Recent Card */}
                    <Grid item xs={12} md={4} lg={3}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 150,
                            }}
                        >
                            <Card title="Total" value={`${report['total']}`} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 150,
                            }}
                        >
                            <Card
                                title="Complete before due"
                                value={`${report['complete']}`}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 150,
                            }}
                        >
                            <Card title="Complete after due" value={`0`} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 150,
                            }}
                        >
                            <Card
                                title="Incomplete"
                                value={`${report['incomplete']}`}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 150,
                            }}
                        >
                            <Card
                                title="Over due"
                                value={`${report['overDue']}`}
                            />
                        </Paper>
                    </Grid>
                </>
                {/* Chart */}
                <Grid item xs={12} md={12} lg={12}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 600,
                        }}
                    >
                        <Chart />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
