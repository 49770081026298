import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PersonIcon from '@mui/icons-material/Person';
import TimelineIcon from '@mui/icons-material/Timeline';
import { ListSubheader } from '@mui/material';
import ReportIcon from '@mui/icons-material/Report';
import { useNavigate } from 'react-router';

export function SideNavBar() {
    const navigate = useNavigate();
    return (
        <List component="nav">
            <ListSubheader component="div" inset>
                Reports
            </ListSubheader>
            <ListItemButton
                onClick={() => {
                    navigate('/DataIntegrity');
                }}
            >
                <ListItemIcon>
                    <ReportIcon />
                </ListItemIcon>
                <ListItemText primary="Data Integrity" />
            </ListItemButton>
            <ListItemButton
                onClick={() => {
                    navigate('/MemberReport');
                }}
            >
                <ListItemIcon>
                    <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="Member" />
            </ListItemButton>
            {/*<ListItemButton*/}
            {/*    onClick={() => {*/}
            {/*        navigate('/TimelineReport');*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <ListItemIcon>*/}
            {/*        <TimelineIcon />*/}
            {/*    </ListItemIcon>*/}
            {/*    <ListItemText primary="Timeline" />*/}
            {/*</ListItemButton>*/}
        </List>
    );
}
