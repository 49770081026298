import * as React from 'react';
import { lazy } from 'react';

// project imports
import { Dashboard } from '../components/Dashboard';
import { Navigate } from 'react-router-dom';
import Loadable from '../components/Dashboard/Common/Loadable';

// dashboard routing
const DataIntegrity = Loadable(
    lazy(() =>
        import('../components/Dashboard').then(({ DataIntegrity }) => ({
            default: DataIntegrity,
        }))
    )
);
const MemberReport = Loadable(
    lazy(() =>
        import('../components/Dashboard').then(({ MemberReport }) => ({
            default: MemberReport,
        }))
    )
);
const TimelineReport = Loadable(
    lazy(() =>
        import('../components/Dashboard').then(({ TimelineReport }) => ({
            default: TimelineReport,
        }))
    )
);

// ==============================|| MAIN ROUTING ||============================== //

const DashboardRoutes = {
    path: '/',
    element: <Dashboard />,
    children: [
        {
            path: '/',
            element: <Navigate to="DataIntegrity" />,
        },
        {
            path: 'DataIntegrity',
            element: <DataIntegrity />,
        },
        {
            path: 'MemberReport',
            element: <MemberReport />,
        },
        {
            path: 'TimelineReport',
            element: <TimelineReport />,
        },
    ],
};

export default DashboardRoutes;
