import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { RechartsPieChart } from './RechartsPieChart';
import Container from '@mui/material/Container';
import { Card } from '../Common';
import { useAppState } from '../../../context/appState';
import { DataIntegrityIssueType } from './constants';
import { RechartsBarChart } from './RechartsBarChart';

export function DataIntegrity() {
    const { dataIntegrityReport } = useAppState();

    // @ts-ignore
    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <>
                    {/* Recent Card */}
                    <Grid item xs={12} md={4} lg={3}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 240,
                            }}
                        >
                            <Card
                                title={`${DataIntegrityIssueType.Good}`}
                                value={`${
                                    dataIntegrityReport[
                                        DataIntegrityIssueType.Good
                                    ]
                                }`}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 240,
                            }}
                        >
                            <Card
                                title={`${DataIntegrityIssueType.NoDue}`}
                                value={`${
                                    dataIntegrityReport[
                                        DataIntegrityIssueType.NoDue
                                    ]
                                }`}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 240,
                            }}
                        >
                            <Card
                                title={`${DataIntegrityIssueType.NoAssignee}`}
                                value={`${
                                    dataIntegrityReport[
                                        DataIntegrityIssueType.NoAssignee
                                    ]
                                }`}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 240,
                            }}
                        >
                            <Card
                                title={`${DataIntegrityIssueType.NoDueNoAssignee}`}
                                value={`${
                                    dataIntegrityReport[
                                        DataIntegrityIssueType.NoDueNoAssignee
                                    ]
                                }`}
                            />
                        </Paper>
                    </Grid>
                </>
                {/* RechartsPieChart */}
                <Grid item xs={12} md={12} lg={12}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 400,
                        }}
                    >
                        <RechartsPieChart />
                    </Paper>
                </Grid>
                {/* RechartsBarChart */}
                <Grid item xs={12} md={12} lg={12}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 600,
                        }}
                    >
                        <RechartsBarChart />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
