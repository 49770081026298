import * as React from 'react';
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import { Title } from '../../Common';
import ChartModal from './ChartModal';
import { useAppState } from '../../../../context/appState';

const data = [
    {
        name: 'Member A',
        total: 4000,
        complete: 2400,
        inProgress: 2400,
        overDue: 2400,
    },
];

interface chartMembers {
    [idMember: string]: chartMember;
}

interface chartMember {
    idMember: string;
    name: string;
    total: number;
    complete: number;
    incomplete: number;
    overDue: number;
}

const getData = (checkItems: []) => {
    const members: chartMembers = {};
    checkItems.forEach((checkItem) => {
        const idMember = checkItem['idMember'];
        const name = checkItem['checkItemMemberName'];
        if (name === '' || checkItem['checkItemDueDate'] === '') {
            return;
        }
        const state = checkItem['checkItemState'];
        if (!members[idMember]) {
            members[idMember] = {
                idMember: idMember,
                name: checkItem['checkItemMemberName'],
                total: 0,
                complete: 0,
                incomplete: 0,
                overDue: 0,
            };
        }
        members[idMember]['total'] += 1;
        // @ts-ignore
        members[idMember][state] += 1;
    });

    return Object.values(members).sort((a, b) => {
        return a.total - b.total;
    });
};

const getSelectedCheckItems = (
    checkItems: any,
    idMember: string,
    state: string
) => {
    return checkItems.filter((checkItem: any) => {
        const name = checkItem['checkItemMemberName'];
        if (name === '' || checkItem['checkItemDueDate'] === '') {
            return false;
        }

        // @ts-ignore
        return (
            checkItem.idMember == idMember && checkItem.checkItemState == state
        );
    });
};

export function Chart() {
    const [open, setOpen] = React.useState(false);
    const handleModalOpen = () => setOpen(true);
    const handleModalClose = () => setOpen(false);
    const { checkItems, setSelectedCheckItems } = useAppState();

    const handleClick = (e: any, index: number, state: string) => {
        if (!e) {
            return;
        }
        setSelectedCheckItems(
            getSelectedCheckItems(checkItems, e.idMember, state)
        );
        handleModalOpen();
    };

    return (
        <>
            <Title>Checkitems by member</Title>
            <ResponsiveContainer>
                <BarChart
                    data={getData(checkItems)}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 20,
                    }}
                    layout="vertical"
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" orientation="top" />
                    <YAxis
                        type="category"
                        dataKey="name"
                        width={100}
                        reversed
                    />
                    <Tooltip />
                    <Legend verticalAlign="top" />
                    <Bar
                        onClick={(e, index) => {
                            handleClick(e, index, 'complete');
                        }}
                        dataKey="complete"
                        name="Complete"
                        stackId="a"
                        fill="#99CC99"
                    />
                    <Bar
                        onClick={(e, index) => {
                            handleClick(e, index, 'incomplete');
                        }}
                        dataKey="incomplete"
                        name="Incomplete"
                        stackId="a"
                        fill="#ffc658"
                    />
                    <Bar
                        onClick={(e, index) => {
                            handleClick(e, index, 'overDue');
                        }}
                        dataKey="overDue"
                        name="Over due"
                        stackId="a"
                        fill="#ff6666"
                    />
                </BarChart>
            </ResponsiveContainer>
            <ChartModal open={open} handleClose={handleModalClose} />
        </>
    );
}
