import * as React from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    BarChart,
    Bar,
} from 'recharts';
import { Title } from '../../Common';

const data = [
    {
        time: '2022.01',
        Member1: 10,
        Member2: 20,
        Member3: 30,
        Member4: 50,
        Member5: 40,
    },
    {
        time: '2022.02',
        Member1: 20,
        Member2: 30,
        Member3: 10,
        Member4: 20,
        Member5: 10,
    },
    {
        time: '2022.03',
        Member1: 20,
        Member2: 10,
        Member3: 30,
        Member4: 30,
        Member5: 20,
    },
    {
        time: '2022.04',
        Member1: 50,
        Member2: 20,
        Member3: 30,
        Member4: 40,
        Member5: 10,
    },
    {
        time: '2022.05',
        Member1: 30,
        Member2: 40,
        Member3: 10,
        Member4: 50,
        Member5: 20,
    },
];

export function Chart() {
    return (
        <>
            <Title>Completion on-time ratio by member</Title>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="time" />
                    <YAxis tickFormatter={(tick) => `${tick}%`} />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="Member1" stroke="#8884d8" />
                    <Line type="monotone" dataKey="Member2" stroke="#82ca9d" />
                    <Line type="monotone" dataKey="Member3" stroke="#ffc658" />
                    <Line type="monotone" dataKey="Member4" stroke="#bc42f5" />
                    <Line type="monotone" dataKey="Member5" stroke="#f54278" />
                </LineChart>
            </ResponsiveContainer>
        </>
    );
}
