import * as React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

// routes
import DashboardRoutes from './DashboardRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export function Routes() {
    return useRoutes([
        DashboardRoutes,
        {
            path: '*',
            element: <Navigate to="/" />,
        },
    ]);
}
