import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Title } from './Title';

export function Card({ title, value }: { title: string; value: string }) {
    return (
        <>
            <Title>{title}</Title>
            <Typography component="p" variant="h4">
                {value}
            </Typography>
        </>
    );
}
