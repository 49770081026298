import React, { createContext, useContext, useState } from 'react';
import {
    DataIntegrityIssueType,
    DataIntegrityReport,
} from '../components/Dashboard/DataIntegrity/constants';

export interface IAppProps {}

export interface IAppState {
    checkItems: [];
    setCheckItems: (value: []) => void;
    selectedCheckItems: [];
    setSelectedCheckItems: (value: []) => void;
    dataIntegrityReport: DataIntegrityReport;
    setDataIntegrityReport: (value: DataIntegrityReport) => void;
}

export const StateContext = createContext<IAppState>(null!);

export const AppStateProvider: React.FC<React.PropsWithChildren<IAppProps>> = ({
    children,
}: React.PropsWithChildren<IAppProps>) => {
    const [checkItems, setCheckItems] = useState<[]>([]);
    const [selectedCheckItems, setSelectedCheckItems] = useState<[]>([]);
    const [dataIntegrityReport, setDataIntegrityReport] =
        useState<DataIntegrityReport>({
            [DataIntegrityIssueType.Good]: 0,
            [DataIntegrityIssueType.NoAssignee]: 0,
            [DataIntegrityIssueType.NoDue]: 0,
            [DataIntegrityIssueType.NoDueNoAssignee]: 0,
        });

    const context = {
        checkItems,
        setCheckItems,
        selectedCheckItems,
        setSelectedCheckItems,
        dataIntegrityReport,
        setDataIntegrityReport,
    };

    return (
        <StateContext.Provider value={context}>
            {children}
        </StateContext.Provider>
    );
};

export function useAppState(): IAppState {
    return useContext(StateContext);
}
