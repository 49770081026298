import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Chart } from './Chart';
import { Card } from '../Common';
import Container from '@mui/material/Container';

export function TimelineReport() {
    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <>
                    {/* Recent Card */}
                    {/*<Grid item xs={12} md={4} lg={3}>*/}
                    {/*    <Paper*/}
                    {/*        sx={{*/}
                    {/*            p: 2,*/}
                    {/*            display: 'flex',*/}
                    {/*            flexDirection: 'column',*/}
                    {/*            height: 240,*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <Card title="Total" value="100" />*/}
                    {/*    </Paper>*/}
                    {/*</Grid>*/}
                </>
                {/* RechartsPieChart */}
                <Grid item xs={12} md={12} lg={12}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 400,
                        }}
                    >
                        <Chart />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
