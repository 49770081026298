import { useAppState } from '../context/appState';

export const useTrelloData = () => {
    const { setCheckItems, setDataIntegrityReport } = useAppState();
    const isDemo = window.location.href.includes('trello-demo');
    const url = isDemo
        ? 'https://4f941zybm7.execute-api.us-east-1.amazonaws.com/default/trelloAPI_demo'
        : 'https://5scodpgds6.execute-api.us-east-1.amazonaws.com/default/trelloAPI_QA';

    const getTrelloData = () => {
        return fetch(url)
            .then((response) => response.json())
            .then((records) => {
                setCheckItems(records['checkItems']);
                setDataIntegrityReport(records['dataIntegrityReport']);
            });
    };

    return { getTrelloData };
};
