import * as React from 'react';
import {
    Cell,
    Legend,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
} from 'recharts';
import { Title } from '../../Common';
import RechartsPieChartModal from './RechartsPieChartModal';
import { useAppState } from '../../../../context/appState';
import { DataIntegrityIssueType, DataIntegrityReport } from '../constants';
import { round } from '../../../../utils/mathUtils';

const data = [
    { name: DataIntegrityIssueType.Good, value: 400, percent: 0.5 },
    { name: 'Group B', value: 300, percent: 0.2 },
    { name: 'Group C', value: 300, percentage: 0.2 },
    { name: 'Group D', value: 200, percentage: 0.2 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
    percent,
    index,
}: any) => {
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            fill="black"
            textAnchor={x > cx ? 'start' : 'end'}
            dominantBaseline="central"
        >
            {value} / {`${(percent * 100).toFixed(2)}%`}
        </text>
    );
};

const getPieData = (report: DataIntegrityReport) => {
    const total =
        report[DataIntegrityIssueType.Good] +
        report[DataIntegrityIssueType.NoAssignee] +
        report[DataIntegrityIssueType.NoDue] +
        report[DataIntegrityIssueType.NoDueNoAssignee];

    return Object.values(DataIntegrityIssueType).map(
        (issue: DataIntegrityIssueType) => {
            return {
                name: issue,
                value: report[issue],
                percent: report[issue] / total,
            };
        }
    );
};

const getSelectedCheckItems = (checkItems: any, issue: string) => {
    return checkItems.filter((checkItem: any) => {
        return checkItem['dataIntegrityIssueType'] === issue;
    });
};

export function RechartsPieChart() {
    const [open, setOpen] = React.useState(false);
    const handleModalOpen = () => setOpen(true);
    const handleModalClose = () => setOpen(false);
    const { checkItems, setSelectedCheckItems, dataIntegrityReport } =
        useAppState();

    const handleClick = (e: any) => {
        if (!e) {
            return;
        }

        setSelectedCheckItems(getSelectedCheckItems(checkItems, e.name));
        handleModalOpen();
    };

    return (
        <>
            <Title>Checkitems</Title>
            <ResponsiveContainer>
                <PieChart width={400} height={400}>
                    <Tooltip />
                    <Legend
                        verticalAlign={'middle'}
                        align={'right'}
                        layout={'vertical'}
                    />
                    <Pie
                        data={getPieData(dataIntegrityReport)}
                        label={renderCustomizedLabel}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        onClick={handleClick}
                    >
                        {data.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                            />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
            <RechartsPieChartModal open={open} handleClose={handleModalClose} />
        </>
    );
}
