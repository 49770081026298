import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Routes } from './routes';
import CssBaseline from '@mui/material/CssBaseline';
import { AppStateProvider } from './context/appState';

const mdTheme = createTheme();

export default function App() {
    return (
        <ThemeProvider theme={mdTheme}>
            <AppStateProvider>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <Routes />
            </AppStateProvider>
        </ThemeProvider>
    );
}
