import * as React from 'react';
import { DataGrid, GridToolbar, GridColDef } from '@mui/x-data-grid';
import { useAppState } from '../../../../context/appState';

const columns: GridColDef[] = [
    { field: 'idBoard', headerName: 'idBoard', hide: true },
    { field: 'idList', headerName: 'idList', hide: true },
    { field: 'idCard', headerName: 'idCard', hide: true },
    { field: 'idChecklist', headerName: 'idChecklist', hide: true },
    { field: 'idCheckItem', headerName: 'idCheckItem', hide: true },
    { field: 'idMember', headerName: 'idMember', hide: true },
    {
        field: 'checkItemState',
        headerName: 'Status',
        width: 150,
        filterable: true,
    },
    {
        field: 'checkItemDueDate',
        headerName: 'Due',
        width: 150,
        filterable: true,
    },
    {
        field: 'checkItemMemberName',
        headerName: 'Assignee',
        width: 150,
        filterable: true,
    },
    {
        field: 'checkItemName',
        headerName: 'Checkitem',
        width: 400,
        filterable: true,
    },
    {
        field: 'cardName',
        headerName: 'Card',
        width: 400,
        filterable: true,
    },
    {
        field: 'listName',
        headerName: 'List',
        width: 100,
        filterable: true,
    },
];

export function RechartsPieChartTable() {
    const { selectedCheckItems } = useAppState();
    const rows = selectedCheckItems.map((checkItem: {}) => {
        // @ts-ignore
        return { id: checkItem['idCheckItem'], ...checkItem };
    });

    return (
        <>
            <div style={{ height: 600, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                />
            </div>
        </>
    );
}
