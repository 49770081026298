export enum DataIntegrityIssueType {
    Good = 'Good',
    NoDue = 'No due',
    NoAssignee = 'No assignee',
    NoDueNoAssignee = 'No due and no assignee',
}

export interface DataIntegrityReport {
    [DataIntegrityIssueType.Good]: number;
    [DataIntegrityIssueType.NoDue]: number;
    [DataIntegrityIssueType.NoAssignee]: number;
    [DataIntegrityIssueType.NoDueNoAssignee]: number;
}
